import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';
/*const MAX_SEARCH_RESULTS = (VOFFICE.searchOptions && VOFFICE.searchOptions.max) || 18;*/
const MAX_SEARCH_RESULTS = 100;
export default {
	data: function () {
		return {
			units: [],
			ids: [],
			resultCount: 0,
			pageCount: 0,
			searching: false,
			lastSearch: undefined
		};
	},

	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {
			fields: this.resultFields,
			filter: {
				unitgroupid: 31586
			},
			sorting: 'random',
			max: MAX_SEARCH_RESULTS
		};

		this.lastSearch = req;
		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
				this.ids = res.ids;
				this.currentPage = 0;
				this.pageCount = Math.ceil(this.ids.length / MAX_SEARCH_RESULTS);
				this.resultCount = res.total || this.ids.length;
			});

	},
	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
		showMore: function () {
			this.searching = true;

			var req = Object.assign({}, this.lastSearch);

			var shown = (this.currentPage + 1) * MAX_SEARCH_RESULTS;
			if (this.ids.length > shown) {
				req.ids = this.ids.slice(shown, Math.min(this.ids.length, shown + MAX_SEARCH_RESULTS));
				this.error = undefined;
				doReq('searchUnits', req)
					.then((res) => {
						this.units = this.units.concat(res.units);
						this.currentPage += 1;
						this.searching = false;
					})
					.catch(e => {
						this.searching = false;
						this.error = e;
					});


			} else {
				//V.logWarn('no more results');
				this.searching = false;
			}

		},

		setPage: function (page) {
			//V.log("setPage", page);
			page--;
			this.units = [];
			this.searching = true;

			var req = Object.assign({}, this.lastSearch);

			var start = page * MAX_SEARCH_RESULTS;
			req.ids = this.ids.slice(start, Math.min(this.ids.length, start + MAX_SEARCH_RESULTS));
			this.error = undefined;
			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;
					this.currentPage = page;
					this.searching = false;
				})
				.catch(e => {
					this.searching = false;
					this.error = e;
				});
		}
	}

};